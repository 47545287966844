<template>
	<div class="container">
		<div class="header">
			<img src="../../assets/imgs/xms.png" alt="">
			<div class="text">
				<p class="title">全要素疫情防控</p>
				<p class="subTitle">All factor epidemic prevention and control</p>
			</div>
		</div>
		
		<div class="active-div">
			<img @click="handleActiveClick('1')" v-show="active != 1" src="../../assets/imgs/xms7.png" alt="">
			<img @click="handleActiveClick('1')" v-show="active == 1" class="active" src="../../assets/imgs/xms1.png" alt="">
			<img @click="handleActiveClick('2')" v-show="active != 2" src="../../assets/imgs/xms2.png" alt="">
			<img @click="handleActiveClick('2')" v-show="active == 2" class="active" src="../../assets/imgs/xms8.png" alt="">
			<img @click="handleActiveClick('3')" v-show="active != 3" src="../../assets/imgs/xms3.png" alt="">
			<img @click="handleActiveClick('3')" v-show="active == 3" class="active" src="../../assets/imgs/xms9.png" alt="">
		</div>
		
		<div class="system">
			<div class="text">
				<p class="subTitle">Little door god</p>
				<p class="title">小门神智慧隔离服务系统</p>
				<div class="line"></div>
			</div>
			<img style="margin-top: 69px;" src="../../assets/imgs/xms10.png" alt="">
		</div>
		
		<div class="ldg">
			<img src="../../assets/imgs/ldg.png" alt="">
			<div class="text">
				<p class="subTitle">Little door god</p>
				<p class="title">小门神</p>
				<div class="line"></div>
				<p class="content">新冠肺炎疫情暴发以来，承德公司研发的“小门神系列 产品”“人脸识别体温检测一体机”“疫情防控管理系统”“智能疫情防控追踪服务系统”“5g智能门磁”在疫情预防、管控期间发挥了重大作用，小门神居家隔离 产品销售到石家庄…</p>
				<img class="img-data" src="../../assets/imgs/xms5.png" alt="">
				<div class="ldg-data">
					<div class="sy">
						<p class="number">159879</p>
						<p class="name">小门神使用量</p>
					</div>
					<div class="fw">
						<p class="number">215876</p>
						<p class="name">服务人数</p>
					</div>
					<div class="cishu">
						<p class="number">3164339</p>
						<p class="name">开门通知次数</p>
					</div>
					<div class="tx">
						<p class="number">2566818</p>
						<p class="name">异常开门提醒</p>
					</div>
				</div>
				
				<div class="btns">
					<p class="see-detalis" @click="handleGoTo('/littleDoorGodsDetails')">查看详情</p>
				</div>
			</div>
			
		</div>
		
		<div class="chamber">
			<div class="text">
				<p class="subTitle">Ultra low energy nucleic acid sampling room</p>
				<p class="title">超低能耗核酸采样室</p>
				<div class="line"></div>
				<p class="content">采样室由河北天乾地坤科技有限公司自主研发生产，采用一体化集成设 计，舱体采用高强度金属材质，加装保温隔离材料，适用高温低寒环境 工作，内部具有新风系统、冷暖空调、多重过滤消杀系统、紫外线定时 消杀、隐藏式垃圾…</p>
				<img class="img-data" src="../../assets/imgs/xms6.png" alt="">
				<div class="btns">
					<p class="see-detalis" @click="handleGoTo('/chamber')">查看详情</p>
				</div>
			</div>
			
			<img src="../../assets/imgs/chamber.png" alt="">
		</div>
		
		<div class="measurement">
			<img src="../../assets/imgs/measurement.png" alt="">
			<div class="text">
				<p class="subTitle">Face recognition temperature measurement</p>
				<p class="title">人脸识别测温</p>
				<div class="line"></div>
				<p class="content">“电子哨兵”由数据管理平台和监控终端组成，可通过刷身份证+人脸核验用户身份，实现健康码、疫苗接种、核酸信息、社区身份信息等智能化识别与核验。</p>
				<div class="details">
					<div class="left">
						<p>3D人脸识别</p>
						<p>超高安全级别</p>
						<p>超薄机身</p>
						<p>无感通行</p>
					</div>
					<div class="right">
						<p>单机超大储存</p>
						<p>强光可识别</p>
						<p>语音播报</p>
						<p>逆光补光</p>
					</div>
				</div>
				<div class="btns">
					<p class="see-detalis" @click="handleGoTo('/allInOne')">查看详情</p>
				</div>
			</div>
		</div>
		
		<div class="imaging">
			<div class="text">
				<p class="subTitle">Multi person thermal imaging</p>
				<p class="title">多人热成像</p>
				<div class="line"></div>
				<p class="content">精准测量，领先的红外人脸识别算法以及彩色人脸识别算法，识别>99.8%，并且在实际使用过程中尚未出现误识别。</p>
				<div class="btns">
					<p class="see-detalis" @click="handleGoTo('/thermometer')">查看详情</p>
				</div>
			</div>
			<img src="../../assets/imgs/imaging.png" alt="">
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				active: '1'
			}
		},
		
		methods: {
			handleGoTo(val){
				this.$router.push(val)
			},
			
			handleActiveClick(val) {
				this.active = val
			}
		}
	}
</script>

<style scoped>
.header {
	position: relative;
}

.header img {
	width: 100%;
}

.header .text {
	position: absolute;
	top: 50%;
	right: 22%;
	transform: translate(0, -50%);
}

.header .text .title {
	height: 48px;
	font-size: 48px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #FFFFFF;
}
.header .text .subTitle {
	height: 18px;
	font-size: 18px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #FFFFFF;
	margin-top: 19px;
}

.active-div {
	padding: 20px 0 47px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-image: url('../../assets/imgs/xms4.png');
	background-size: cover;
}
.active-div  img {
	cursor: pointer;
}
.active-div .active  {
	position: relative;
	text-align: center;
	top: -70px;
}

.ldg {
	width: 1200px;
	margin: 0 auto;
	padding: 141px 106px 0;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
}

.ldg .text .line {
	margin: 28px 0 14px;
}

.ldg .text .content {
	width: 565px;
	font-size: 14px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #666666;
	line-height: 20px;
}

.ldg .text .img-data {
	margin: 52px 0 2px;
}

.subTitle {
	height: 20px;
	font-size: 20px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #D7D7D7;
	text-transform: uppercase;
}

.title {
	height: 50px;
	font-size: 36px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #333333;
	line-height: 50px;
}

.line {
	width: 45px;
	height: 4px;
	background: #184BAB;
}

.ldg-data {
	width: 586px;
	position: relative;
}

.ldg-data div {
	text-align: center;
}

.ldg-data .number {
	height: 26px;
	font-size: 22px;
	font-family: DINAlternate-Bold, DINAlternate;
	font-weight: bold;
	color: #666666;
	line-height: 26px;
}

.ldg-data .name {
	height: 12px;
	font-size: 12px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #666666;
}

.ldg-data .sy {
	position: absolute;
	top: 0;
	left: 0;
}

.ldg-data .fw {
	position: absolute;
	left: 168px;
}

.ldg-data .cishu {
	position: absolute;
	right: 155px;
}
.ldg-data .tx {
	position: absolute;
	right: -15px;
}

.ldg .btns {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	margin-top: 109px;
}

.see-detalis {
	width: 150px;
	height: 50px;
	line-height: 50px;
	border-radius: 25px;
	border: 1px solid #184BAB;
	font-size: 22px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #184BAB;
	text-align: center;
	cursor: pointer;
}

.chamber {
	width: 1200px;
	margin: 0 auto;
	padding: 122px 106px 0;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.chamber .text .line {
	margin: 47px 0 17px;
}

.chamber .text .content {
	width: 542px;
	font-size: 14px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #666666;
	line-height: 20px;
}
.chamber .btns {
	margin-top: 45px;
}

.measurement {
	width: 1200px;
	margin: 0 auto;
	padding: 184px 106px 0;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.measurement .line {
	margin: 28px 0 10px;
}

.measurement .content {
	width: 565px;
	font-size: 14px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #666666;
	line-height: 20px;
}

.measurement .details {
	margin-top: 56px;
	display: flex;
	align-items: center;
}

.measurement .details p {
	margin-bottom: 12px;
	height: 20px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 20px;
}

.measurement .details .left {
	margin-right: 300px;
}

.measurement .btns {
	margin-top: 54px;
	display: flex;
	justify-content: flex-end;
}

.imaging {
	width: 1200px;
	margin: 0 auto;
	padding: 175px 106px 88px;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.imaging  .line {
	margin: 41px 0 20px;
}

.imaging .content {
	width: 542px;
	font-size: 14px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #666666;
	line-height: 20px;
}
.imaging  .btns {
	margin-top: 215px;
}

.system {
	width: 1200px;
	margin: 91px auto 0;
	padding: 0 106px;
	box-sizing: border-box;
}

.system .text .title {
	margin-bottom: 28px;
}
</style>